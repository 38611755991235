import React, { useRef, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import {Helmet} from 'react-helmet'
import ReactPlayer from "react-player"
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import Seo from '../components/seo'
import SectDesafios from "../components/section-desafios"
import SectEtapas from "../components/section-etapas"
import SectPorQue from "../components/section-por-que"

import ArrowLg from "../images/arrow-lg.inline.svg"

import IcoShare from '../images/share.inline.svg'
import IcoTw from '../images/ico-twitter.inline.svg'
import IcoFb from '../images/ico-facebook.inline.svg'
import IcoWa from '../images/ico-whatsapp.inline.svg'

import { gsap } from 'gsap'



const Challenge = (props) => {
  const { languages, originalPath, language } = useI18next()

  const trackDownload = () => {
    typeof window !== 'undefined' &&
      window.gtag('event', 'click', {
        event_category: 'download',
        event_label: 'download-bases',
      })
  }

  const [isReady, setIsReady] = useState(false)

  const playerRef = useRef()

    useEffect(() => {
      isReady &&
        playerRef.current &&
        gsap.to(playerRef.current, { autoAlpha: 1, delay: 0.5 })
    }, [isReady])


  return (
    <Layout location={props.location}>
      <Helmet bodyAttributes={{ class: 'page-header-green' }}></Helmet>
      <Seo title='Acerca de SAVIA' />
      <section className='pt-32 pb-12 lg:pb-24 bg-green'>
        <div className='container grid gap-4 mx-auto lg:gap-16 header-challenge'>
          <span className='px-4 text-sm text-center lg:text-xl lg:text-left header-challenge__text-top lg:px-0 lg:pr-4'>
            <Trans>Programa de Packaging Sostenible</Trans>
          </span>

          <div
            ref={playerRef}
            className='w-full transition-opacity duration-700 delay-100 opacity-0 transform-gpu player-wrapper'
          >
            <ReactPlayer
              url='https://vimeo.com/554747033'
              controls={true}
              className=' react-player'
              width='100%'
              height='100%'
              onReady={() => {
                setIsReady(true)
              }}
            />
          </div>

          <span className='px-4 text-sm text-center lg:text-left lg:text-xl header-challenge__text-bottom lg:px-0 lg:pr-4'>
            <Trans>Imaginando el futuro de nuestras fibras</Trans>
          </span>
        </div>

        <div className='container p-4 mx-auto'>
          <div className='flex items-center justify-center w-auto pb-4 ml-auto lg:pb-0'>
            <IcoShare className='w-4 mr-3' />
            <p className='pr-4'>
              <Trans>Comparte en</Trans>:
            </p>
            <ul className='flex items-center space-x-2 share-buttons'>
              <a
                target='_blank'
                rel='noreferrer'
                href={`https://twitter.com/intent/tweet?url=https://saviapackaging.com/about&text=Conoce%20m%C3%A1s%20sobre%20el%20programa%20Savia%20Packaging%20en%20&via=somoscmpc`}
                className='flex items-center px-2 py-1 rounded-full lg:px-4 share-tw bg-green'
              >
                <IcoTw className='w-6 text-black fill-current' />
                Twitter
              </a>
              <a
                target='_blank'
                rel='noreferrer'
                href={`https://www.facebook.com/sharer.php?u=https://savia.netlify.com/about`}
                className='flex items-center px-2 py-1 rounded-full lg:px-4 share-tw bg-green'
              >
                <IcoFb className='w-6 text-black fill-current' />
                Facebook
              </a>
              <a
                target='_blank'
                rel='noreferrer'
                href={`whatsapp://send?text=https://saviapackaging.com/about`}
                className='flex items-center px-2 py-1 rounded-full lg:px-4 share-tw bg-green'
              >
                <IcoWa className='w-6 text-black fill-current' />
                WhatsApp
              </a>
            </ul>
          </div>
        </div>
      </section>
      <section className='py-20 challenge-content__intro bg-green'>
        <div className='container mx-auto'>
          <p className='px-4 mb-20 text-3xl font-extrabold leading-tight text-white lg:text-5xl'>
            <Trans i18nKey='aboutInvitation'>
              CMPC te invita a sumarte al desafío de la crisis climática{' '}
              <span className='text-orange'>
                abriendo espacios para la colaboración e invirtiendo en la
                construcción de soluciones
              </span>{' '}
              que nos permitan aspirar a un mundo que use materiales con un
              menor impacto, orientado a la bioeconomía circular, creando valor
              a través del uso de recursos biológicos renovables, reutilizando y
              reciclando.
            </Trans>
          </p>

          <a
            className='flex items-center justify-center w-full px-6 py-3 mb-10 text-2xl font-semibold text-white uppercase border-none rounded-full lg:max-w-xs button-noticias bg-orange'
            href='https:///app.saviapackaging.com/es/guia/'
          >
            Postula
          </a>
        </div>
      </section>
      <section className='bg-black challenge-content__about text-green'>
        <p className='container px-4 mx-auto text-2xl font-extrabold leading-tight py-14 lg:py-40 lg:text-5xl'>
          <Trans i18nKey='aboutTextLong'>
            Savia es un programa de innovación abierta de CMPC, que
            <span className='text-white'>
              busca soluciones de packaging sostenible con alto potencial de
              escalabilidad
            </span>
            , que generen valor para los consumidores y la sociedad en general,
            y contribuyan a superar desafíos globales que son relevantes para el
            futuro de la industria y el planeta.
          </Trans>
        </p>

        <hr className='border border-green' />

        <div className='container grid px-4 mx-auto text-white lg:divide-y-0 lg:divide-x lg:grid-cols-2 divide-green'>
          <div className='flex row-start-2 pl-0 lg:row-start-auto lg:p-14'>
            <a
            onClick={trackDownload}
              className='items-center justify-center w-full px-6 py-3 mt-auto mb-10 text-2xl font-bold text-black uppercase border-none rounded-full lg:w-auto lg:inline-flex button-noticias bg-green'
              download
              href={`/assets/bases-${language}.pdf`}
            >
              <Trans>Descarga las bases</Trans>
            </a>
          </div>
          <div className='px-4 py-14 lg:pr-0 lg:p-14'>
            <p className='mb-4 text-2xl font-bold'>
              {' '}
              <Trans>SAVIA lo hacemos en alianza con otros</Trans>{' '}
            </p>
            <p className='mb-4 text-base'>
              <Trans>
                Contamos con una red global, que incluye distintas alianzas con
                líderes mundiales en diseño e innovación y con entidades
                públicas. También incorporamos en el proceso a las empresas de
                las que somos proveedores, para detectar sus necesidades y las
                de los consumidores, y así apuntar a desarrollos que sean útiles
                y generen beneficios en toda la cadena de valor.
              </Trans>
            </p>
            <p className='text-base'>
              <Trans>
                De ese modo, impulsamos un ecosistema, estableciendo una mayor
                conexión y colaboración entre academia, empresa, sociedad civil,
                Estado y redes internacionales.
              </Trans>
            </p>
          </div>
        </div>
      </section>
      <SectDesafios />
      <section className='w-screen overflow-x-hidden lg:pb-16 challenge-content__postula bg-green'>
        <div className='container flex flex-col px-4 mx-auto lg:flex-row'>
          <div className='flex flex-col w-auto pt-16 lg:pr-10 lg:border-r lg:w-1/2 lg:pt-28 postula__left'>
            <h3 className='mb-8 text-sm underline uppercase lg:text-base'>
              <Trans>¿A quiénes buscamos?</Trans>
            </h3>

            <p className='mb-8 text-2xl font-extrabold leading-tight lg:mb-8 lg:text-3xl'>
              <Trans i18nKey='postulaTextLong'>
                Buscamos
                <span className='underline'>equipos emprendedores</span> de todo
                el mundo, que tengan soluciones de packaging sostenibles
                <span className='underline'>
                  listas para implementar o en proceso final de desarrollo
                </span>
                .
              </Trans>
            </p>

            <p>
              <Trans>
                En SAVIA pueden postular empresas, emprendimientos,
                instituciones, desarrolladores, diseñadores, etc...
              </Trans>
            </p>

            <ArrowLg className='hidden my-24 mr-24 lg:block'></ArrowLg>

            <div className='flex flex-col mt-10 space-y-4 lg:mt-0'>
              <Link
                className='flex items-center justify-center w-full px-6 py-3 mb-2 text-2xl font-semibold text-white uppercase border-none rounded-full lg:max-w-xs button-noticias bg-orange'
                to='/faq/'
              >
                <Trans>Revisa las Q&A</Trans>
              </Link>

              <a
              onClick={trackDownload}
                className='flex items-center justify-center w-full px-6 py-3 text-2xl font-semibold text-black uppercase border rounded-full lg:max-w-xs button-noticias'
                download
                href={`/assets/bases-${language}.pdf`}
              >
                <Trans>Descarga las bases</Trans>
              </a>
            </div>
          </div>

          <div className='relative flex flex-col items-center overflow-visible lg:w-1/2 postula__right'>
            <div className='w-full h-10 border-black spacer lg:h-52'></div>

            <div className='relative flex flex-col items-center justify-center mb-16 lg:mb-0'>
              <span
                className='inset-0 flex items-center justify-center rounded-full lg:-mt-16 lg:-mb-16 bg-ball bg-beige'
                // style={{ width: 844, height: 844, top: '-71%', left: '-7%' }}
              >
                <p className='relative z-0 w-screen px-4 text-2xl font-extrabold lg:px-0 lg:max-w-xl lg:text-3xl'>
                  <Trans>
                    SAVIA es un espacio privilegiado para diseñar, prototipar y
                    pilotear el packaging del futuro, en compañía de CMPC y una
                    red global de líderes de la industria.
                  </Trans>
                </p>
              </span>
            </div>
          </div>
        </div>
        <hr className='border-black' />
      </section>
      <SectEtapas className='py-24 bg-green'></SectEtapas>
      <SectPorQue></SectPorQue>
      {/* <aside className='border-t border-b bg-green'>
        <div className='container px-4 mx-auto py-28'>
          <a
            className='flex items-center justify-center w-full px-6 py-3 mx-auto text-2xl font-semibold uppercase bg-black border-none rounded-full text-green lg:max-w-xs button-noticias'
            href='#/'
          >
            ¡Postula aquí!
          </a>
        </div>
      </aside> */}
    </Layout>
  )
}

export default Challenge

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index", "about"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`